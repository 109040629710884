<template>
  <!-- :modules="modules" -->
  <Swiper
    :autoplay="true"
    :allow-touch-move="allowTouchMove"
    :auto-height="autoHeight"
    :style="{'--swiper-navigation-color': '#d2b48c'}"
    :navigation="{disabledClass: 'opacity-0'}"
    :slides-per-group="slidesPerView"
    :slides-per-view="slidesPerView"
    :space-between="spaceBetween"
    @swiper="setSwiper"
  >
    <slot>
      <div />
    </slot>
  </Swiper>
</template>
<script>
// Import Swiper Vue.js components
import { ref } from 'vue'
import { Swiper } from 'swiper/vue'

// Navigation
import SwiperCore, { Navigation } from 'swiper'
SwiperCore.use([Navigation])

export default {
  name: 'Slider',
  components: {
    Swiper
  },
  props: {
    autoHeight: {
      type: Boolean,
      default: true
    },
    slidesPerView: {
      type: Number,
      default: 1
    },
    spaceBetween: {
      type: Number,
      default: 50
    },
    allowTouchMove: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const swiper = ref(null)

    const setSlide = (index) => {
      swiper.value.slideTo(index)
    }

    const setSwiper = (obj) => {
      swiper.value = obj
    }

    return { setSlide, setSwiper }
  }
}
</script>
<style lang="scss" module>

</style>
